import CopyAllIcon from '@mui/icons-material/CopyAll';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, FormControl, IconButton, InputAdornment, Snackbar, TextField } from '@mui/material';
import React from 'react';
import { CopyableTextField } from './copyableTextField';
import { PromptTextField } from './promptTextField';
const { Configuration, OpenAIApi } = require("openai");


export class FormClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prompt: '',
            response: '',
            textFieldResponseFocused: false,
            loading: false,
            copied: false,
            openai: null,
            error: null
        };
    }

    componentDidMount() {
        const key = window.sessionStorage.getItem("key")
        if (key != null) {
            this.initializeOpenAiClient(key)
        }
    }

    initializeOpenAiClient(key) {
        const configuration = new Configuration({
            apiKey: key
        });

        this.setState({
            openai: new OpenAIApi(configuration),
        });
    }

    handleKeyChange(event) {
        event.preventDefault();
        window.sessionStorage.setItem("key", event.target.value)
        this.initializeOpenAiClient(event.target.value)
    }

    handlePromptChange(event) {
        event.preventDefault();
        this.setState({
            textFieldInput: event.target.value,
            textFieldResponseFocused: false
        });
    }

    handleResponseChange(event) {
        event.preventDefault();
        this.setState({
            response: event.target.value,
        });
    }

    handleInputSubmit = async (event) => {
        event.preventDefault();
        const {
            textFieldInput,
            openai
        } = this.state;

        this.setState({
            loading: true
        })

        await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: textFieldInput }],
        }).then((response) => {
            this.setState({
                response: response.data.choices[0].message.content,
                textFieldResponseFocused: true,
                loading: false
            });
        }).catch((error) => {
            this.setState({
                error: error
            })
        })
    }

    handleResponseFieldCopy(event) {
        event.preventDefault()
        const {
            response,
        } = this.state;

        navigator.clipboard.writeText(response)
        this.setState({
            copied: true
        })
    }

    render() {
        if (this.state.error != null) {
            return (
                <Alert severity="error">{this.state.error.message}</Alert>
            )
        }

        if (this.state.openai == null) {
            return (
                <FormControl fullWidth sx={{ m: 1, width: 800, maxWidth: '100%' }}>
                    <FormControl>
                        <TextField
                            id="key"
                            label="Key"
                            plceholder="Key"
                            variant="filled"
                            focused={true}
                            onChange={(e) => this.handleKeyChange(e)}
                            margin="normal"
                            inputProps={{ style: { color: "white" } }}
                        />
                    </FormControl>
                    <h1>set a key</h1>
                </FormControl>
            )
        }

        return (
            <FormControl fullWidth sx={{ m: 1, width: 800, maxWidth: '100%' }}>
                <FormControl>
                    <TextField
                        id="key"
                        label="Key"
                        plceholder="Key"
                        variant="filled"
                        focused={true}
                        onChange={(e) => this.handleKeyChange(e)}
                        margin="normal"
                        inputProps={{ style: { color: "white" } }}
                    />

                    <PromptTextField
                        onChange={(e) => this.handlePromptChange(e)}
                    />
                    <LoadingButton
                        onClick={(e) => this.handleInputSubmit(e)}
                        endIcon={<SendIcon />}
                        loading={this.state.loading}
                        variant="contained"
                    >
                        <span>Send</span>
                    </LoadingButton>
                </FormControl>

                <FormControl>
                    <CopyableTextField
                        id="response"
                        label="Response"
                        value={this.state.response}
                        multiline
                        rows={12}
                        color="success"
                        onChange={(e) => this.handleResponseChange(e)}
                        margin="normal"
                        focused={this.state.textFieldResponseFocused}
                        inputProps={{ style: { color: "white" } }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton edge="end" color="primary" onClick={(e) => this.handleResponseFieldCopy(e)}>
                                        <CopyAllIcon />
                                    </IconButton>
                                </InputAdornment>,
                        }}
                    />
                    <Snackbar
                        open={this.state.copied}
                        onClose={(_) => this.setState({ copied: false })}
                        autoHideDuration={2000}
                        message="Copied to clipboard"
                    />
                </FormControl>
            </FormControl>
        );
    }
}
