import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CopyableTextField = styled(TextField)(({ theme }) => ({
    "& .MuiTextField-root": {
        margin: theme.spacing(),
        width: "25ch"
    },
    "& .MuiOutlinedInput-root": {
        position: "relative"
    },
    "& .MuiIconButton-root": {
        position: "absolute",
        top: 0,
        right: 10
    }
}));
