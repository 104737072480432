import { FormControl, TextField } from '@mui/material';

import React from 'react';

export class PromptTextField extends React.Component {
    constructor(props) {
        super(props);

        this.handleTextChange = props.onChange.bind(this)
    }


    render() {
        return (
            <FormControl>
                <TextField
                    id="prompt"
                    label="Prompt"
                    multiline
                    variant="filled"
                    rows={12}
                    color="success"
                    onChange={(e) => this.handleTextChange(e)}
                    margin="normal"
                    inputProps={{ style: { color: "white" } }}
                />
            </FormControl >
        )
    }
}
